.rootForImage {
  width: 100%;
  /* width: 300px; */
  height: 100%;
}

.product {
  background-color: #fff;
  border: 1px solid #e7edf1;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.12); */
  box-shadow: 0 0px 0px rgb(0 0 0 / 8%), 0 0px 8px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.productImageWrapper {
  position: relative;
  overflow: hidden;
  & img {
    transition: all 200ms ease;
  }
  &:hover {
    & img {
      transform: scale(1.1);
    }
  }

  & p:first-of-type {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    margin: 0;
    color: #fff;
    font-size: 0.8rem;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
  }
}

.productBodyWrapper {
  padding: 0 1rem;
  & h3 {
    /* font-size: 0.8rem; */
    /* color: #999; */
    /* font-weight: normal; */
    line-height: 1.4em;
    /* margin: 0; */
    /* text-overflow: ellipsis; */

    /* Needed to make it work */
    /* overflow: hidden; */
    /* white-space: nowrap; */
  }
}

.productPrice {
  & p {
    margin: 0;
    margin-top: 0.5rem;
    color: #111;
    font-size: 0.8rem;
    font-weight: 600;
  }
}
.co2Container {
  display: flex;
  justify-content: space-between;
}

.star {
  fill: orange;
}
.price {
  color: var(--marketplaceColor);
  font-weight: 600;
  margin: 0;
  font-size: 14px;
}
.classDate {
  margin-bottom: 0;
  line-height: 1.4;
  font-weight: 500;
}
.weekLabel {
  font-weight: 600;
  font-style: italic;
}
