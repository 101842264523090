@import '../../styles/propertySets.css';



.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}
.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.hey {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
  & :hover {
    color: var(--matterColor) !important;
  }
}



.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.title {
  @apply --marketplaceH1FontStyles;
}
.image {
  width: 150px;
  height: 100px;
  border-radius: 5px;
  padding: 0px 10px;
}
.image:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.imageHoverEffect {
  transition: transform 0.3s ease;
}
.imageHoverEffect:hover {
  transform: scale(1.1);
}
.container {
  margin: 0 1rem;
  /* margin: 0rem 3rem; */
}
.mainContainer {
  display: flex;
}
.logo {
  color: var(--marketplaceColor);
  font-weight: 600;
  font-size: 2rem;
}
.logoDisbaled {
  color: lightgray;
  font-weight: 600;
  font-size: 2rem;
}
.error {
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin-top: 1rem;
  color: red;
  font-weight: normal;
  font-size: 1rem;
}

.loadingContainer {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    & svg {
      height: 40px;
    }
  }
}
